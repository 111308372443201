import axios from "axios";


/**
 * axios instancia
 */
let instance = axios.create({
    baseURL: process.env.REACT_APP_URL,
    headers: {
        "Content-type": "application/json"
    }
});
export const http = instance;
