import React, { useEffect, useState } from "react";
import  useAuth  from "../../contexts/authContext";
import europool_logo from "../../img/europool_system.png";
import vpo_logo from "../../img/vpo_azul_marino.png";



export const LoginView = () => {

    const { login } = useAuth();

    const [user,setUser] = useState<string>('')
    const [password,setPassword] = useState<string>('')
    const [error,setError] = useState<boolean>(false)
    const [loading,setLoading] = useState<boolean>(false)


    useEffect(() => {
        setError(false)
    },[user,password])

    async function handleInicioSesion() {
        setLoading(true);
        login(user,password)
            // .then(
            //     (res:any) => 
            //         navigate(URL_FLUJO)
            //     )
            .catch((reason:any) => {
                setLoading(false);
                setError(true);
            })

    }

    const handleKeyDown = (event: any) => {
        if(event.key === "Enter"){
            handleInicioSesion();
        }
    }


    return(
        
        <div className="login__page">
            <div>
                <div className="login__container">
                    <div className="login__logo">
                        <img src={europool_logo} alt={'Logo europool'}></img>
                    </div>
                    <div className="login__titulo"><label>Flow Analytics</label></div>
                    <div className="login__inputs">
                        <div className={error ? 'login__inputs-error' : ''}><label htmlFor="">Nombre de Usuario</label><input type="text" onChange={(e)=> setUser(e.target.value)} onKeyDown={handleKeyDown} /></div>
                        <div className={error ? 'login__inputs-error' : ''}><label htmlFor="">Contraseña</label><input type="password" onChange={(e)=> setPassword(e.target.value)} onKeyDown={handleKeyDown} /></div>
                    </div>
                    <div className="login__iniciar-sesion"><button disabled={loading} onClick={handleInicioSesion}>Iniciar sesión</button></div>
                </div>
                <div className="vpo_logo">
                    <a href="https://vpoingenieria.com" target={"_blank"}>Powered By<img src={vpo_logo} alt={'Logo europool'}></img></a>
                </div>
            </div>
        </div>
    )
}



