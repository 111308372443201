import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { URLS_NAVEGACION, URL_ANALISIS, URL_FLUJO } from "../../../constants/constants";
import europool_logo from "../../../img/europool_system.png";
import europool_icono from "../../../img/europool_system_icono.png";
import arrows from "../../../img/arrows.svg";
import barchar from "../../../img/bar-chart.svg";
import shutdown from "../../../img/shutdown.svg";
import  useAuth  from "../../../contexts/authContext";


type PropsAside = {
    children?: JSX.Element;
    asideFlujos?: boolean;  
  };

const Aside = (props: PropsAside) => {

    const [plegado, setPlegado] = useState(false);
    const [children, setChildren] = useState(props.children);
    const location = useLocation();
    const {logout} = useAuth()
    // const [titulo,setTitulo] = useState<string>('');

    // useEffect( () => {
    //     let urlNavegacion = URLS_NAVEGACION.find( elem => elem.url ===  location.pathname);
    //     setTitulo(urlNavegacion !== undefined ? urlNavegacion?.texto : '')
    // },[location])

    function estaSeleccionado(url: string){
        if(url === location.pathname){
            return 'seleccionado';
        }
        return ''
    }

    function plegarDesplegar(plegado: boolean){
        if(props.asideFlujos){
            setPlegado(plegado); 
        } 
    }

    return (
            <aside className={plegado ? "aside-plegado" : ""}>
                <div>
                    <div className="aside__logo" onClick={() => plegarDesplegar(!plegado)}>
                        <img src={plegado ? europool_icono: europool_logo} alt={'Logo europool'}></img>
                    </div>
                    <nav>
                        <Link to={URL_FLUJO} className={estaSeleccionado(URL_FLUJO)}> <span><img src={arrows} alt="Flechas"/>Flujos</span></Link>
                        <Link to={URL_ANALISIS} className={estaSeleccionado(URL_ANALISIS)}><span><img src={barchar} alt="Bar char"/> Análisis</span></Link>
                    </nav>
                    {!plegado &&
                        props.children
                    }
                </div>
                {!plegado &&
                    <div className="aside__logout">
                        <div onClick={logout}>
                            <img src={shutdown} alt="" />
                            <span>Cerrar sesión</span>
                        </div>
                    </div>
                }
            </aside>

    )
}
export default Aside


