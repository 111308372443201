import { type } from "@testing-library/user-event/dist/type";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { flujoValues, tieneSeleccionadoTodoProcesosFrescos } from "../../pages/Flujos";
import { getStock } from "../../services/FlujosService";
import { getFecha } from "../../utils/Fechas";

export const OPCION_TIPO_REFERENCIA_MERCANCIAS = 'todo';
export const OPCION_TIPO_REFERENCIA_ENVASES = '1';
export const OPCION_TIPO_REFERENCIA_MAXICRATE = '2';

export const OPCION_FLUJO_TODO = 'todo';
export const OPCION_FLUJO_FRESCO = '1';
export const OPCION_FLUJO_SECO = '2';

export const OPCION_FLUJO_FRESCO_FRUTA = '1';
export const OPCION_FLUJO_FRESCO_REFRIGERADO = '2';
export const OPCION_FLUJO_FRESCO_CARNE = '3';
export const OPCION_FLUJO_FRESCO_PESCADO = '4';

//export const OPCION_FLUJO_SECO_ALIMENTACION = '5';
//export const OPCION_FLUJO_SECO_BAJAROTACION = '6';

type PropsFiltrosFlujos = {
    filtros: flujoValues;
    callbackFiltrosCambiado: (filtros: flujoValues) => void
}

const FiltrosFlujos = (props: PropsFiltrosFlujos) => {

    const checkboxRefFrescos = React.useRef<HTMLInputElement | null>(null);
    const checkboxRefSecos = React.useRef<HTMLInputElement | null>(null);

    useEffect( () => {
        //al cambiar el filtro de flujo, mira los procesos para añadirlos o quitarlos
       if(checkboxRefFrescos.current){

           if(props.filtros.flujo.indexOf(OPCION_FLUJO_FRESCO) > -1){
               if (!tieneSeleccionadoTodoProcesosFrescos(props.filtros))
                // && (proceso.indexOf(OPCION_FLUJO_FRESCO_FRUTA) > -1 || proceso.indexOf(OPCION_FLUJO_FRESCO_REFRIGERADO) > -1 || proceso.indexOf(OPCION_FLUJO_FRESCO_CARNE) > -1 || proceso.indexOf(OPCION_FLUJO_FRESCO_PESCADO) > -1 )
                { 
                   checkboxRefFrescos.current.indeterminate = true;
                }else{
                   checkboxRefFrescos.current.indeterminate = false;
                }
                                
            }
        }

        //if(checkboxRefSecos.current){
        //    if(flujo.indexOf(OPCION_FLUJO_SECO) > -1){
        //        if (!tieneSeleccionadoTodoProcesosSecos()
        //         // && (proceso.indexOf(OPCION_FLUJO_SECO_ALIMENTACION) > -1 || proceso.indexOf(OPCION_FLUJO_SECO_BAJAROTACION) > -1 )
        //        ){ 
        //            checkboxRefSecos.current.indeterminate = true;
        //        }else{
        //            checkboxRefSecos.current.indeterminate = false;
        //        }
        //    }
        //}
    },[props.filtros.proceso])

    const flujoFrescoCambiado = (e:React.ChangeEvent<HTMLInputElement>) => {
        let check = e.target.checked;
        flujoCambiado(check, OPCION_FLUJO_FRESCO );
    } 

    const flujoSecoCambiado = (e:React.ChangeEvent<HTMLInputElement>) => {
        let check = e.target.checked;
        flujoCambiado(check, OPCION_FLUJO_SECO );
    } 

    const flujoCambiado = (check: boolean, opcionSeleccionada: string) => {
        let filtros = {...props.filtros}

        let flujoClone = Object.assign([], filtros.flujo);
        if(check === true){
            flujoClone.push(opcionSeleccionada)
        }else{
            const index = flujoClone.indexOf(opcionSeleccionada);
            if (index > -1) { // only splice array when item is found
                flujoClone.splice(index, 1); // 2nd parameter means remove one item only
            }
        }

        filtros.flujo = flujoClone;

        if(check === true){
            console.log("1",filtros)
            seleccionarTodoProcesos(opcionSeleccionada, filtros);
        }else{
            console.log("2",filtros)
            deseleccionarTodoProcesos(opcionSeleccionada, filtros);
        }
        
        props.callbackFiltrosCambiado(filtros);
    }

    function seleccionarTodoProcesos(opcionSeleccionada: string, filtros: flujoValues){
        let procesoClone = Object.assign([], filtros.proceso);
        if(opcionSeleccionada === OPCION_FLUJO_FRESCO){
            if (procesoClone.indexOf(OPCION_FLUJO_FRESCO_FRUTA) === -1) { // only splice array when item is found
                procesoClone.push(OPCION_FLUJO_FRESCO_FRUTA); }
            if (procesoClone.indexOf(OPCION_FLUJO_FRESCO_REFRIGERADO) === -1) { // only splice array when item is found
                procesoClone.push(OPCION_FLUJO_FRESCO_REFRIGERADO); }
            if (procesoClone.indexOf(OPCION_FLUJO_FRESCO_CARNE) === -1) { // only splice array when item is found
                procesoClone.push(OPCION_FLUJO_FRESCO_CARNE); }
            if (procesoClone.indexOf(OPCION_FLUJO_FRESCO_PESCADO) === -1) { // only splice array when item is found
                procesoClone.push(OPCION_FLUJO_FRESCO_PESCADO); }
        }
        //else if(opcionSeleccionada === OPCION_FLUJO_SECO){
        //    if (procesoClone.indexOf(OPCION_FLUJO_SECO_ALIMENTACION) === -1) { // only splice array when item is found
        //        procesoClone.push(OPCION_FLUJO_SECO_ALIMENTACION); }
        //    if (procesoClone.indexOf(OPCION_FLUJO_SECO_BAJAROTACION) === -1) { // only splice array when item is found
        //        procesoClone.push(OPCION_FLUJO_SECO_BAJAROTACION); }
        //}
        filtros.proceso = procesoClone;
        props.callbackFiltrosCambiado(filtros);
    }
    function deseleccionarTodoProcesos(opcionSeleccionada: string, filtros: flujoValues){
        let procesoClone = Object.assign([], filtros.proceso);
        if(opcionSeleccionada === OPCION_FLUJO_FRESCO){
            let index = procesoClone.indexOf(OPCION_FLUJO_FRESCO_CARNE);
            if (index > -1) {  procesoClone.splice(index, 1); }

            index = procesoClone.indexOf(OPCION_FLUJO_FRESCO_FRUTA);
            if (index > -1) { procesoClone.splice(index, 1); }

            index = procesoClone.indexOf(OPCION_FLUJO_FRESCO_PESCADO);
            if (index > -1) { procesoClone.splice(index, 1); }

            index = procesoClone.indexOf(OPCION_FLUJO_FRESCO_REFRIGERADO);
            if (index > -1) {   procesoClone.splice(index, 1); }
        }
        //else if(opcionSeleccionada === OPCION_FLUJO_SECO){
        //    let index = procesoClone.indexOf(OPCION_FLUJO_SECO_ALIMENTACION);
        //    if (index > -1) { 
        //        procesoClone.splice(index, 1); }
        //    index = procesoClone.indexOf(OPCION_FLUJO_SECO_BAJAROTACION);
        //    if (index > -1) { 
        //        procesoClone.splice(index, 1); }
        //}
        filtros.proceso = procesoClone;
        props.callbackFiltrosCambiado(filtros);
    }

    const procesoCambiado = (check: boolean, opcionSeleccionada: string) => {
        let filtros = {...props.filtros}

        let procesoClone = Object.assign([], filtros.proceso);
        if(check === true){
            procesoClone.push(opcionSeleccionada)
        }else{
            const index = procesoClone.indexOf(opcionSeleccionada);
            if (index > -1) { // only splice array when item is found
                procesoClone.splice(index, 1); // 2nd parameter means remove one item only
            }
        }
        
        filtros.proceso = procesoClone;
        props.callbackFiltrosCambiado(filtros);
    }

    const diaDesdeCambiado = (diaDesde: string | undefined) => {
        let filtros = {...props.filtros}
        filtros.diaDesde = diaDesde;
        props.callbackFiltrosCambiado(filtros);
    }

    const diaHastaCambiado = (diaHasta: string | undefined) => {
        let filtros = {...props.filtros}
        filtros.diaHasta = diaHasta;
        props.callbackFiltrosCambiado(filtros);
    }
    
    const tipoReferenciaCambiado = (tipoReferencia: string) => {
        let filtros = {...props.filtros}
        filtros.tipoReferencia = tipoReferencia;
        props.callbackFiltrosCambiado(filtros);
    }

    const fechaMinLimiteDesde = useMemo(() => {
        return new Date('2022-11-02').toISOString().split("T")[0];
    }, [])
    const fechaMaxLimite = useMemo(() => {

            const fechaHoy = new Date();
            const dosDiasMenos = new Date(fechaHoy.setDate(fechaHoy.getDate() - 2));
            return dosDiasMenos.toISOString().split("T")[0];
    }, [])
    const fechaMinLimiteHasta = useMemo(() => {
        if( props.filtros.diaDesde){
            return new Date(props.filtros.diaDesde).toISOString().split("T")[0];
        }
        else{
            return fechaMinLimiteDesde
        }
    }, [props.filtros.diaDesde])

    return(
        <div className="aside-flujos__filtros">

            <h3>Filtros</h3>
            <div className="aside-flujos__filtros__fechas">
                <div>

                <label>Desde</label><input type={'date'}  min={fechaMinLimiteDesde} max={fechaMaxLimite}  value={props.filtros.diaDesde} onChange={(e) => diaDesdeCambiado(e.target.value)} />
                </div>
                <div>
                <label>Hasta</label><input type={'date'}  min={fechaMinLimiteHasta} max={fechaMaxLimite}  value={props.filtros.diaHasta} onChange={(e) => { diaHastaCambiado(e.target.value)}} />
                </div>
            </div>
            {/*
            <div className="aside-flujos__filtros__flujos">

                <div>
                    <label>Frescos<input type={'checkbox'}  ref={checkboxRefFrescos} checked={props.filtros.flujo.indexOf(OPCION_FLUJO_FRESCO) > -1}  onChange={flujoFrescoCambiado} /></label>
                        {(props.filtros.flujo.indexOf(OPCION_FLUJO_FRESCO) > -1) &&
                            <>
                                <label>Carne<input type={'checkbox'} checked={props.filtros.proceso.indexOf(OPCION_FLUJO_FRESCO_CARNE) > -1} onChange={(e) => procesoCambiado(e.target.checked, OPCION_FLUJO_FRESCO_CARNE)} /></label>
                                <label>Pescado<input type={'checkbox'} checked={props.filtros.proceso.indexOf(OPCION_FLUJO_FRESCO_PESCADO) > -1} onChange={(e) =>procesoCambiado(e.target.checked, OPCION_FLUJO_FRESCO_PESCADO)} /></label>
                                <label>Refrigerado<input type={'checkbox'} checked={props.filtros.proceso.indexOf(OPCION_FLUJO_FRESCO_REFRIGERADO) > -1} onChange={(e) =>procesoCambiado(e.target.checked, OPCION_FLUJO_FRESCO_REFRIGERADO)} /></label>
                                <label>Fruta<input type={'checkbox'} checked={props.filtros.proceso.indexOf(OPCION_FLUJO_FRESCO_FRUTA) > -1} onChange={(e) =>procesoCambiado(e.target.checked, OPCION_FLUJO_FRESCO_FRUTA)} /></label>
                            </>
                        }

                </div>
                <div>
                <label>Secos<input type={'checkbox'} ref={checkboxRefSecos}  checked={props.filtros.flujo.indexOf(OPCION_FLUJO_SECO) > -1} onChange={flujoSecoCambiado} /></label>
                        {/*(flujo.indexOf(OPCION_FLUJO_SECO) > -1) &&
                            <>
                                <label>Alimentación<input type={'checkbox'} checked={proceso.indexOf(OPCION_FLUJO_SECO_ALIMENTACION) > -1} onChange={(e) =>procesoCambiado(e.target.checked, OPCION_FLUJO_SECO_ALIMENTACION)} /></label>
                                <label>Baja Rotación<input type={'checkbox'} checked={proceso.indexOf(OPCION_FLUJO_SECO_BAJAROTACION) > -1} onChange={(e) =>procesoCambiado(e.target.checked, OPCION_FLUJO_SECO_BAJAROTACION)} /></label>
                            </>
                        */}{/*
                </div>
            </div>
            <div className="aside-flujos__filtros__procesos">
                    <label>
                        <input type="radio" name="color"  checked={props.filtros.tipoReferencia === OPCION_TIPO_REFERENCIA_ENVASES}  onChange={() =>tipoReferenciaCambiado(OPCION_TIPO_REFERENCIA_ENVASES)} /> Envases
                    </label>
                    <label>
                        <input type="radio" name="color" checked={props.filtros.tipoReferencia === OPCION_TIPO_REFERENCIA_MERCANCIAS}  onChange={() =>tipoReferenciaCambiado(OPCION_TIPO_REFERENCIA_MERCANCIAS)}/> Mercancias
                    </label>
                    <label>
                        <input type="radio" name="color"  checked={props.filtros.tipoReferencia === OPCION_TIPO_REFERENCIA_MAXICRATE}  onChange={() =>tipoReferenciaCambiado(OPCION_TIPO_REFERENCIA_MAXICRATE)}/> Maxicrate
                    </label>
            </div>
            */}
        </div>
    )
}
export default FiltrosFlujos



