import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import { URL_ANALISIS, URL_FLUJO, URL_LOGIN } from './constants/constants';
import Flujos from './pages/Flujos/Flujos';
import Analisis from './pages/Analisis/Analisis';
import Header from './components/core/Layout/Header';
import { AuthProvider } from './contexts/authContext';
import  useAuth  from "./contexts/authContext";
import LoginView from './pages/Login';
import WithAxios from './services/utils/WithAxios';



function App() {
    return (
      <AuthProvider>
			<WithAxios>
				<Router>

				<div className="App">
					<PublicLayout />
				</div>
				</Router>
				
			</WithAxios>
      </AuthProvider>
    );
}

export default App;



/**** Parte Publica ****/
const PublicLayout = () => {

	const {authed, loginSiSesionAnterior} = useAuth();
	const navigate = useNavigate()
	const location = useLocation();

	useEffect( () => {
		if(authed === undefined){
			//comprobamos si sesion está activa
			loginSiSesionAnterior();
		}
	},[])

	useEffect( () => {
		if(authed === false){
			// redirigimos a la pagina login:
			navigate(URL_LOGIN)
		}else if(authed === true && location.pathname === URL_LOGIN ){
			// al loguearse si está en la página de login redirigimos a la pagina principal:
			navigate(URL_FLUJO)
		}
	},[authed])

	return(

			authed === false ?
				<Routes>
					<Route  path={URL_LOGIN} element={<LoginView/>} />
				</Routes>
			:
				<ProtectedLayout />
			
	)
}



/**** Parte Privada ****/

const ProtectedLayout = () => {

	const {logout} = useAuth();


		return(
			<>
				{/* <Header></Header> */}
					<Routes>
						<Route path={URL_FLUJO} element={<Flujos/>} />
						<Route path={URL_ANALISIS} element={<Analisis/>} />
					</Routes>
						{/* <div style={{position: 'absolute', top: '1rem',right: '1rem'}}><button onClick={logout}>Logout</button></div> */}
			</>
		);
	
	
}


