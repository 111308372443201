import { http } from "./utils/http";


const postLogin = (user:string, password: string) => {
    return http.post( "/login/",
        {
            "username": user,
            "password": password
        }
    );

}

const getLogout = () => {
    return http.get( "/logout/");

}
const getSesion = () => {
    return http.get( "/session/");
}





//*************** Forma de llamarlo ***************//

// getDevices().then((value) => {
//     console.log(value)
// })

export default { postLogin, getLogout, getSesion};
