import {AxiosError, AxiosRequestConfig} from "axios";

import {http} from "./http";
import  useAuth, { getToken }  from "../../contexts/authContext";

interface WithAxiosProps {
    children: JSX.Element;
}

const WithAxios = ({ children }: WithAxiosProps) => {

        const { logout } = useAuth();

        http.interceptors.request.use((config:  AxiosRequestConfig) => {
            if( config !== undefined && !config.url?.includes("/login")){
                // Enviar la cabecera cuando se realiza la request
                // Añadir api token
                const apiToken = "Token " + getToken();
                if (apiToken) {
                    config.headers = { "Authorization": apiToken};
                }
            }
            // config.withCredentials = true
            
            return config
            }, error => {
            return Promise.reject(error)
        });
    
        // Control de error en la response
        http.interceptors.response.use((response) => {
            return response;
            },(error: AxiosError ) => {
                if (error?.response?.status === 401) {
                    logout();
                } 
                return Promise.reject(error);
        })
    return children
    }
    
export default WithAxios