import axios from "axios";
import go from "gojs";
import { ReactDiagram } from "gojs-react";
import React, { useEffect, useRef, useState } from "react";
import { getAlertas, getIndicadoresAutoconsumo, getIndicadoresEntradas, getIndicadoresSalida, getIndicadoresTotal, getLinksGeneral, getMasterNodos, getNodosGeneral, getRatioTiendas, getStock } from "../../services/FlujosService";
import { getNodeTemplate } from "../core/GoJS/NodeTemplate";
import plataformaSource from "../../img/Plataforma.png";
import proveedorSource from "../../img/Proveedor.png";
import retornoSource from "../../img/Retorno.png";
import tiendasSource from "../../img/Tiendas.png";
import europoolSource from "../../img/Europool.png";
import { OPCION_FLUJO_FRESCO, OPCION_FLUJO_SECO } from "../Filtros/FiltrosFlujos";
import DetalleNodoSeleccionado from "./DetalleNodoSeleccionado";
import { flujoValues } from "../../pages/Flujos";

type PropsDiagramaGoJS= {
    urlFiltros: string; //obtenemos los filtros para añadir a la url 
    filtrosValues: flujoValues;
}

export type ratioTienda = {
    alerta: number;
    total: number;
    amarillo: number;
    rojo: number;
}

export type NodoFlujosFields = {

    category: string;
    ind: string;
    indDato:string;
    indDatoIn: number | null;
    indDatoOut: number | null;
    indDatoAuto: number | null;
    indDatoPorc: number | null;
    indDatoDif : number | null;
    indDatoDiasStock: number | null;
    loc: string
    icon: string;
    key: number;
    nodo_id: number;
    nodo_tipo_id: number;
    plataforma_flujo_desc: string;
    plataforma_flujo_id:number;
    plataforma_perimetro_desc:string;
    plataforma_perimetro_id:number;
    retorno_perimetro_desc:string
    retorno_perimetro_id:number;
    text:string;
    tienda_perimetro_desc:string;
    tienda_perimetro_id:number;
    stock?: number;
    ratioTienda?: ratioTienda;
    porcentajeRatio?: number;
    colorAlertaRatio?: string | null;
    plataforma_gbi_desc?: string; 
    rojo?: number;
    amarillo?: number;
    alertColor?: string
}

const DiagramaGoJS = (props: PropsDiagramaGoJS) => {

    const [nodosMaestro, setNodosMaestro] = useState< any[] | null>(null);
    const [datosNodos, setDatosNodos] = useState<go.ObjectData[] | []>([]);
    const [datosLinks, setDatosLinks] = useState<go.ObjectData[] | undefined>(undefined);

    const [nodoSeleccionado, setNodoSeleccionado] = useState< any >(undefined);
  
    let diagramRef = useRef<any | undefined>(undefined)


    useEffect( () => {

            axios.all([
                getIndicadoresAutoconsumo(props.urlFiltros)
                ,getIndicadoresEntradas(props.urlFiltros)
                ,getIndicadoresSalida(props.urlFiltros)
                ,getLinksGeneral(props.urlFiltros)
                ,getIndicadoresTotal(props.urlFiltros)
                ,getRatioTiendas(getUrlRatioTienda())
                ,nodosMaestro === null ? getMasterNodos() : undefined
            ]).then(axios.spread((indAutoconumo,indEntradas,indSalidas,links,indTotal, ratioTiendas, nodos) => {
                if(nodos){
                    setNodosMaestro(nodos.data)
                }
                if(indAutoconumo?.status === 200 && links?.status === 200 && indEntradas?.status === 200 && indSalidas?.status === 200){
                    getAlertas({entradas: indEntradas.data, salidas: indSalidas.data, autoconsumos: indAutoconumo.data,  fecha__gte: props.filtrosValues.diaDesde,
                        fecha__lte:props.filtrosValues.diaHasta}).then((alertas) => {

                        let pos =  {
                            'proveedor': [1000, 10], //fila1 coord.y=10
                            'fribasa': [300, 300], //fila1 coord.y=10
                            'europool': [500, 10], //fila1 coord.y=10
                            'plat_madrid': [900, 300], //fila2 coord.y=300
                            'plat_norte': [20, 600], //fila3 coord.y=600
                            'plat_caprabo': [1200, 600], //fila3 coord.y=600
                            'plat_vegonsa': [1600, 600], //fila3 coord.y=600
                            'plat_baleares': [1800, 600], //fila3 coord.y=600
                            'centros': [100, 900], //fila4 coord.y=900
                            'retorno': [600, 1200]  //fila5 coord.y=1200
                        }
                        let jsonNodos = crearJsonNodos(nodosMaestro===null?nodos?.data: nodosMaestro, links.data, pos, indAutoconumo?.data, indEntradas?.data, indSalidas?.data, alertas?.data, ratioTiendas?.data )
                        setDatosNodos(jsonNodos)
                    } );
                }

                if(links?.status === 200 && indTotal?.status === 200){
                    
                    // crearJsonLinksY(nodos.data,links.data,pos, indAutoconumo.data, indEntradas.data, indSalidas.data,indTotal.data )
                    // crearJsonYNodos(nodos.data,links.data,pos, indAutoconumo.data, indEntradas.data, indSalidas.data,indTotal.data )
                    let jsonLinks = crearJsonLinks(nodosMaestro===null?nodos?.data: nodosMaestro, links.data, indTotal.data )
                    setDatosLinks(jsonLinks)
                }
                    
            }))

            //console.log(datosNodos)
    },[props.urlFiltros])

    function crearJsonNodos(nodos: any, links:any, pos:any, indAutoconumo:any, indEntradas:any, indSalidas:any, alertas: any, ratioTiendas:any ){
        // var uniqueFromNodos: number[] = links.map((it:any) => it.from_nodo__id ).filter((value: any, index: any, self: any) => self.indexOf(value) === index) 
        // var uniqueToNodos: number[] = links.map((it:any) => it.to_nodo__id ).filter((value: any, index: any, self: any) => self.indexOf(value) === index) 
        var uniqueToNodos2: number[] = [...links.map((it:any) => it.from_nodo__id ), ...links.map((it:any) => it.to_nodo__id )].filter((value: any, index: any, self: any) => self.indexOf(value) === index) 

        uniqueToNodos2.sort(function (a, b) {
            return nodos.findIndex((e:any) => e.key === a) - nodos.findIndex((e:any) => e.key === b);
        });
        
        let json = uniqueToNodos2.map((nodoId: number, index: number) => {
            let nodo = nodos.find( (n: any) => n.key === nodoId) as NodoFlujosFields;
        
            if(nodo !== undefined){
                let ind_entrada = typeof indEntradas[nodo.key] !== 'undefined' ? Math.ceil(indEntradas[nodo.key]) : 0
                let ind_salida = typeof indSalidas[nodo.key] !== 'undefined' ? Math.ceil(indSalidas[nodo.key]) : 0
                let ind_autoconsumo = typeof indAutoconumo[nodo.key] !== 'undefined' ? Math.ceil(indAutoconumo[nodo.key]) : 0

                // Colocar el indicador en el formato que queramos dentro del nodo
                let dif = ind_entrada + ind_autoconsumo - ind_salida;
    
                //const inValue = ind_entrada !== 0 ? `IN: ${ind_entrada.toLocaleString('de-DE')}\n` : ``
                //const outValue = ind_salida !== 0 ? `OUT: ${ind_salida.toLocaleString('de-DE')}\n` : ``
                //const autoValue = ind_autoconsumo !== 0 ? `AUTOC.: ${ind_autoconsumo.toLocaleString('de-DE')}\n` : ``
                //const porcValue = (ind_entrada !== 0 && ind_salida !== 0) ? `${(ind_salida * 100 / (ind_entrada + ind_autoconsumo)).toFixed(2).toLocaleString()}%\n` : ``
                //const difValue = (ind_entrada !== 0 && ind_salida !== 0) ? `DIF: ${dif.toLocaleString('de-DE')}` : ``

                
                //para el aside
                nodo.indDatoIn = ind_entrada !== 0 ? ind_entrada : null;
                nodo.indDatoOut = ind_salida !== 0 ? ind_salida : null;
                nodo.indDatoAuto = ind_autoconsumo !== 0 ?  ind_autoconsumo: null;
                nodo.indDatoPorc = (ind_entrada + ind_autoconsumo) !== 0 ? (ind_salida / (ind_entrada + ind_autoconsumo))*100 : null;
                nodo.indDatoDif = (ind_entrada !== 0 && ind_salida !== 0) ? dif : null;
    
                // console.log(ratioTiendas)
                nodo.ratioTienda = ratioTiendas[nodoId]
                nodo.colorAlertaRatio = null
                if(nodo.ratioTienda){
                    if(nodo.ratioTienda.alerta !== undefined && nodo.ratioTienda.total !== undefined){
                        
                        let porcentaje = (nodo.ratioTienda?.alerta/nodo.ratioTienda?.total) * 100
                        nodo.porcentajeRatio = porcentaje;
                        
                        if(porcentaje >= nodo.ratioTienda?.amarillo && porcentaje <= nodo.ratioTienda?.rojo){
                            nodo.colorAlertaRatio = "#C9C56D"
                        }else if(porcentaje > nodo.ratioTienda?.rojo){
                            nodo.colorAlertaRatio = "#FF0000"
                        }
                        
                    }
                    
                }

                // Color de los indicadores
                const alert = alertas[nodoId];
                if(alert){
                    //console.log(alert)
                    nodo.indDato = alert.value ? alert.value.toLocaleString('de-DE') + "\n" : "N/A\n";
                    nodo.indDatoDiasStock = alert.value;
                    nodo.alertColor = alert.color;
                    nodo.rojo = alert.rojo
                    nodo.amarillo = alert.amarillo

                }

                // Categoría
                // Más adelante puede que necesitemos diferentes tipos de nodo según sus características. De momento todos los nodos siguen la plantilla "general"
                nodo.category = 'general'


                // Posiciones e iconos
                if (nodo.nodo_tipo_id === 1) {
                    nodo.loc = pos['europool'].join(' ')
                    nodo.icon = europoolSource
                }
                else if (nodo.nodo_tipo_id === 2) {

                    nodo.icon = proveedorSource
                    if (nodo.nodo_id === 1) {
                        nodo.loc = pos['proveedor'].join(' ')
                    }
                    else {
                        nodo.loc = pos['fribasa'].join(' ')
                    }
                }
                else if (nodo.nodo_tipo_id === 3){
                    nodo.icon = plataformaSource
                    if (nodo.plataforma_perimetro_id === 2){
                        nodo.loc = pos['plat_madrid'].join(' '); //el atributo loc tiene que tener la coordenada x y la y separados por un espacio
                        pos['plat_madrid'][0] += 225; //Le añadimos 200 pixels a la coordenada x para que el siguiente nodo se dibuje más a la derecha
                    }
                    else{
                        //plataformas que sirven mayormente a norte
                        if (nodo.plataforma_perimetro_id === 1){
                            nodo.loc = pos['plat_norte'].join(' ');
                            pos['plat_norte'][0] += 225;
                        }
                        //plataformas que sirven mayormente a caprabo
                        else if (nodo.plataforma_perimetro_id === 6){
                            nodo.loc = pos['plat_caprabo'].join(' ');
                            pos['plat_caprabo'][0] += 225;
                        }
                        //plataformas que sirven mayormente a baleares
                        else if (nodo.plataforma_perimetro_id === 5){
                            nodo.loc = pos['plat_baleares'].join(' ');
                            pos['plat_baleares'][0] += 225;
                        }
                        else if (nodo.plataforma_perimetro_id === 4){
                            nodo.loc = pos['plat_vegonsa'].join(' ');
                            pos['plat_vegonsa'][0] += 225;
                        }
                    }
                }

                // Posición perímetros
                if (nodo.nodo_tipo_id === 4){
                    nodo.icon = tiendasSource
                    nodo.loc = pos['centros'].join(' '); //el atributo loc tiene que tener la coordenada x y la y separados por un espacio
                    pos['centros'][0] += 250;
                }
                // Posición centros de retorno
                if (nodo.nodo_tipo_id === 5){
                    nodo.icon = retornoSource
                    nodo.loc = [660, 1200].join(' ');
                }
            }
            return nodo
            
        })
        // limpiarlo de undefinded
        return json.filter( e => e !== undefined)
      
    }

    function crearJsonLinks(nodos: any, links:any, total:any ){
         


        return links.map( (link: any, index: number) => {
            
            link.from = link.from_nodo__id
            link.to = link.to_nodo__id
    
            const nodoFrom = nodos.find((e:any) => ((e.key === link.from && e.plataforma_flujo_id !== null) ||  (e.key === link.to && e.plataforma_flujo_id !== null)));
            // Color e indicadores
            link.type = nodoFrom?.plataforma_flujo_id?.toString() === OPCION_FLUJO_FRESCO ? '#146F8F': nodoFrom?.plataforma_flujo_id?.toString() === OPCION_FLUJO_SECO ?'#FFA545' : 'gray'
            link.textind = '3'
            link.textfrac = '0.4'
    
            // Grosor proporcional al total de flujo que se mueve
            // if (tipo_envase === '3'){
            //     link.width = link.qty * 50 / total.qty < 1 ? 1 : link.qty * 50 / total.qty
            // } else {
                link.width = link.qty * 200 / total.qty < 1 ? 1 : link.qty * 200 / total.qty
            // }
                
            link.width = Math.min(50, link.width)

                // Borrar los atributos que no necesitamos. Puede que sean más los que nos sobren más adelante.
            link.qty = Math.ceil(link.qty)
            delete link.from_nodo__nodo_desc
            delete link.from_nodo__nodo_id
            delete link.from_nodo__nodo_tipo_id
            delete link.from_nodo__nodo_tipo_desc
            delete link.from_nodo_id
            delete link.from_nodo_desc
            delete link.to_nodo__nodo_desc
            delete link.to_nodo__nodo_id
            delete link.to_nodo__nodo_tipo_id
            delete link.to_nodo__nodo_tipo_desc
            delete link.to_nodo_id
            delete link.to_nodo_desc
    

            return link
        })
    

    }

    function resizeDiagram(){
        if(diagramRef){
            const diagram = diagramRef.current.getDiagram();
            if (diagram instanceof go.Diagram) {
                // let offsetWidth = diagram.offsetWidth;
                // let div = diagram.div;
                // div.style.width = (offsetWidth * 0.95).toString() + "px";
                // div.style.height = ( window.innerHeight * 0.83).toString() + "px";
                diagram.zoomToFit();
            }
        }
    }

    function highlightAll() {
        if(diagramRef){
            const diagram = diagramRef.current.getDiagram();
            if (diagram instanceof go.Diagram) {
                //console.log("1111 nodes clik", diagram.nodes)
                diagram.nodes.each(function (n) {
                    n.isHighlighted = true;
                });
                diagram.links.each(function (l) {
                    l.isHighlighted = true;
                })
            }
        }
        setNodoSeleccionado(undefined)
    }

    function animacionLinks(){
    
        if(diagramRef){
            const diagram = diagramRef.current.getDiagram();
            if (diagram instanceof go.Diagram) {
                let animation = new go.Animation();
                animation.easing = go.Animation.EaseLinear;
                diagram.links.each(link => animation.add(link.findObject("PIPE")!, "strokeDashOffset", 20, 0));
                // Run indefinitely
                animation.runCount = Infinity;
                animation.start();
            }
            // let diagram = diagramRef.current.getDiagram()
        }
    }

    function handleModelChange(changes: any) {
        //console.log("1111 handleModelChange", changes)
        animacionLinks();
        resizeDiagram();
        highlightAll();
    }

    function nodeClick(node: NodoFlujosFields){
        if(node.nodo_tipo_id === 3 || node.nodo_tipo_id === 4){
            
            axios.all([
                //getRatioTiendas(getUrlRatioTienda(node))
                ,getStock(getUrlStock(node))
            ])
            .then(axios.spread((stockResp) => {
                
                if(stockResp?.status === 200 && stockResp.data.length>0){ 
                    node.stock = stockResp.data[0].stock_envases
                }else{
                    node.stock = undefined
                }

                setNodoSeleccionado(node);
            }))
            .catch((err) => {
                console.log('FAIL', err)
                setNodoSeleccionado(node);
            });
        }else{
            
            setNodoSeleccionado(node);
        }
               
        // setKeyNodoSeleccionado(node)
    }


    function getUrlRatioTienda(){
        let url = "";

        if (props.filtrosValues.diaDesde !== '' && props.filtrosValues.diaHasta !== '') {
            url += `fecha__gte=${props.filtrosValues.diaDesde}&fecha__lte=${props.filtrosValues.diaHasta}`;
        }

        // if(nodo?.nodo_id ){
        //     url += '&region_id='+nodo.nodo_id
        // }

        url += "&aggregate=true"

        if(url !== ''){
            url = '?' + url;
        }

        //console.log(url)
        return url;
    }

    function getUrlStock(nodo: NodoFlujosFields){
        let url = "";
        //console.log(props.filtrosValues)
        //console.log(nodo)
        if (props.filtrosValues.diaDesde !== '' && props.filtrosValues.diaHasta !== '') {
            url += `fecha=${props.filtrosValues.diaHasta}`;
        }

        if(nodo?.nodo_id){
            if(nodo.nodo_tipo_id === 4){
                url += '&region_id='+nodo.nodo_id
            }else if(nodo.nodo_tipo_id === 3){
                url += '&nodo_id='+nodo.nodo_id
            }
        }

        if(url !== ''){
            url = '?' + url;
        }

        return url;
    }




    return(
        <div className="main-flujos__diagrama-gojs">
            <ReactDiagram
                ref={diagramRef}
                initDiagram={()=> initDiagram(nodeClick, highlightAll)}
                divClassName='diagram-component'
                
                nodeDataArray={datosNodos}
                linkDataArray={datosLinks}
                onModelChange={handleModelChange}
                
            />
            <div className={"detalle-nodo" + (nodoSeleccionado ? " detalle-nodo-clickado" : "")}>
                {nodoSeleccionado &&
                    <DetalleNodoSeleccionado nodo={nodoSeleccionado} onClose={()=>setNodoSeleccionado(undefined)} key={nodoSeleccionado} filtrosValues={props.filtrosValues}></DetalleNodoSeleccionado>  
                }
            </div>
        </div>
    )
}
export default DiagramaGoJS





/**
 * Diagram initialization method, which is passed to the ReactDiagram component.
 * This method is responsible for making the diagram and initializing the model and any templates.
 * The model's data should not be set here, as the ReactDiagram component handles that via the other props.
 */
function initDiagram(nodeClick: Function, highlightAll: Function) {
    const $ = go.GraphObject.make;
    // set your license key here before creating the diagram: go.Diagram.licenseKey = "...";
    const diagram =
      $(go.Diagram,
        {
            "toolManager.mouseWheelBehavior": go.ToolManager.WheelZoom,
            'hasHorizontalScrollbar': true,
            "hasVerticalScrollbar": true, //es suficiente con el scroll vertical de la ventana del navegador
            "allowHorizontalScroll":true, //el diagrama es más ancho que la ventana del navegador por lo que necesitaremos movernos en horizontal
            'undoManager.isEnabled': true,  // must be set to allow for model change listening
            "toolManager.hoverDelay": 150,
            // "toolManager.doMouseHover":  function(e:any) {
            //     // this.doWaitAfter(100)
            //         console.log("DOMOUSEHOVER",this)
            //       go.ToolManager.prototype.doMouseHover.call(this);
            //     //    this.standardWaitAfter(600);  // try for a second hover event
            //     // }
            //   },
            //contentAlignment: go.Spot.Center,
            // 'undoManager.maxHistoryLength': 0,  // uncomment disable undo/redo functionality
            //   'clickCreatingTool.archetypeNodeData': { text: 'new node', color: 'lightblue' },
            initialAutoScale: go.Diagram.Uniform,
            model: new go.GraphLinksModel(
                {
                linkKeyProperty: 'key'  // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
                }),

        });
  
    // define a simple Node template
    diagram.nodeTemplateMap = getNodeTemplate(diagram, nodeClick);
      

    // ---------- LINKS ---------------//

    // Dependiendo de diferentes parámetros se tendrá que dibujar de un color u otro
    // y de un grosor u otro.
    function makeLinkColor(propName:string, width:string) {
        return $(go.Shape, {isPanelMain: true, shadowVisible: false},
        new go.Binding("stroke", propName),
        new go.Binding("strokeWidth", width),
        // new go.Binding("stroke", "isHighlighted", function(h) { return h ? blue : green; }).ofObject()
        )
    }

    // Plantilla para los links
    diagram.linkTemplate =
        $(go.Link,
            { routing: go.Link.AvoidsNodes,
               layerName: "Foreground",
              corner: 12,
              reshapable: true,
              toShortLength: 7,
              curve: go.Link.JumpOver,
              isShadowed: true,
              relinkableFrom: true, relinkableTo: true,
            },
            new go.Binding("points").makeTwoWay(),
            makeLinkColor("type", "width"),
            $(go.Shape,
                { isPanelMain: true, stroke: "white", strokeWidth: 3, name: "PIPE", strokeDashArray: [10, 10]},
            ),
            new go.Binding("opacity", "isHighlighted", function(h) { return h ? 1 : .1; }).ofObject(),


    );
    diagram.click = function(e) {
        e.diagram.commit(function(d) { d.clearHighlighteds();highlightAll() }, "no highlighteds");
      };
    //   function highlightAll() {
    //     diagram.nodes.each(function (n) {
    //         n.isHighlighted = true;
    //     });
    //     diagram.links.each(function (l) {
    //         l.isHighlighted = true;
    //     })
    // }

    //   $(go.Node, 'Auto',  // the Shape will go around the TextBlock
    //     new go.Binding('location', 'loc', go.Point.parse).makeTwoWay(go.Point.stringify),
    //     $(go.Shape, 'RoundedRectangle',
    //       { name: 'SHAPE', fill: 'white', strokeWidth: 0 },
    //       // Shape.fill is bound to Node.data.color
    //       new go.Binding('fill', 'color')),
    //     $(go.TextBlock,
    //       { margin: 8, editable: true },  // some room around the text
    //       new go.Binding('text').makeTwoWay()
    //     )
    //   );
     /*---------------------------------------------------*/
    //------------------- ANIMATION ---------------------//
    /*---------------------------------------------------*/
    
    
    
    
    return diagram;
    
    
}

  /**
   * This function handles any changes to the GoJS model.
   * It is here that you would make any updates to your React state, which is dicussed below.
   */

  
