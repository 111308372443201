
import React from "react";
import go from "gojs";
import { reduceEachLeadingCommentRange } from "typescript";
import { relative } from "path";
// Plantilla para el nodo general (el que más se utilizará)

export function getNodeTemplate (diagram: go.Diagram, nodeClick: Function):go.Map<any,any>{
    const $ = go.GraphObject.make;
let generalTemplate =
    $(go.Node,
        "Vertical",
        {
            cursor: 'pointer',
            selectionAdorned: false,
            resizable: false,
            isShadowed: true, shadowBlur: 26,
            shadowOffset: new go.Point(3.18, 10.1),
            shadowColor: "rgba(0, 0, 0, .14)",
            fromSpot: go.Spot.BottomSide,  // coming out from right side
            toSpot: go.Spot.TopSide,
            fromLinkable: true, fromLinkableSelfNode: true, fromLinkableDuplicates: true,
            toLinkable: true, toLinkableSelfNode: true, toLinkableDuplicates: true,
            // cornerRounding: 5,

            // background: 'red',
            // doubleClick: info,// end Auto Panel
            // mouseHover: function(e: go.InputEvent, node:any) {
            //     // highlight all Links and Nodes coming out of a given Node
               
            //     // let node = nod as go.Node;
            //     // nod.get('key')
            //     let diagram = node.diagram;
            //     if(diagram !== null){
            //         diagram.startTransaction("highlight");
            //         // remove any previous highlighting
            //         diagram.clearHighlighteds();
            //         node.isHighlighted = true;
            //         // for each Link coming out of the Node, set Link.isHighlighted
            //         node.findLinksInto().each(function(l:any) { l.isHighlighted = true; });
            //         node.findLinksOutOf().each(function(l:any) { l.isHighlighted = true; });
            //         // for each Node destination for the Node, set Node.isHighlighted
            //         node.findNodesInto().each(function(n:any) { n.isHighlighted = true; });
            //         node.findNodesOutOf().each(function(n:any) { n.isHighlighted = true; });
            //         diagram.commitTransaction("highlight");
            //     }
            // },
            // mouseLeave: function (){
            //     if (diagram instanceof go.Diagram) {
            //         console.log("1111 nodes clik", diagram.nodes)
            //         diagram.nodes.each(function (n) {
            //             n.isHighlighted = true;
            //         });
            //         diagram.links.each(function (l) {
            //             l.isHighlighted = true;
            //         })
            //     }
            // },

            click: function(e: go.InputEvent, node:any) {
                // highlight all Links and Nodes coming out of a given Node
               
                // let node = nod as go.Node;
                // nod.get('key')
                nodeClick(node.data);
                let diagram = node.diagram;
                if(diagram !== null){
                    diagram.startTransaction("highlight");
                    // remove any previous highlighting
                    diagram.clearHighlighteds();
                    node.isHighlighted = true;
                    // for each Link coming out of the Node, set Link.isHighlighted
                    node.findLinksInto().each(function(l:any) { l.isHighlighted = true; });
                    node.findLinksOutOf().each(function(l:any) { l.isHighlighted = true; });
                    // for each Node destination for the Node, set Node.isHighlighted
                    node.findNodesInto().each(function(n:any) { n.isHighlighted = true; });
                    node.findNodesOutOf().each(function(n:any) { n.isHighlighted = true; });
                    diagram.commitTransaction("highlight");
                }
            }
        },
        new go.Binding("location", "loc", go.Point.parse),
        new go.Binding("shadowColor", "alertColor", function(n) { 
            
            switch (n){
                case "rojo":
                    return "#FF010136"
                    break;
                case "amarillo":
                    return "#C9C56DAB"
                    break;  
                case "blanco":
                default:
                    return "#234B5D40"
                    break; 
            }

        }),
        // $(
        //     go.Panel,
        //     "Rectangle",
        //         {width: 120, height: 30, background: "white", margin: 0},
        //         new go.Binding("background", "ind"),
        //         $(
        //             go.TextBlock,
        //             {shadowVisible: false, width: 119, height: 30, textAlign: "center", text: "verticalAligment: Center", verticalAlignment: go.Spot.Center},
        //             new go.Binding("text")
        //         ),
        //         new go.Binding("opacity", "isHighlighted", (h) => { return h ? 1 : 0.1; }).ofObject(),
        // ),
        $(
            go.Panel,
            "Position",
            $(
                go.Panel,
                "Auto",
                
                {  shadowVisible: true, alignment: go.Spot.Center},
                
                $(
                    go.Shape,  // the border
                    "RoundedRectangle",
                    {  shadowVisible: true, strokeWidth: 1.5, fill: "white",},
                    new go.Binding("stroke", "alertColor", function(n) { 
                         
                        switch (n){
                            case "rojo":
                                return "#EF0000"
                                break;
                            case "amarillo":
                                return "#C9C56D"
                                break;  
                            case "blanco":
                            default:
                                return "transparent"
                                break; 
                        }
            
                    }),
                    // the Shape.stroke color depends on whether Node.isHighlighted is true
                    // new go.Binding("stroke", "isHighlighted", function(h) { return h ? 'blue' : "transparent"; }),
                ),
                $(go.Panel, "Vertical",
                    {alignment: go.Spot.Center, margin: 10 },
                    $(
                        go.Panel,
                        "Horizontal",  
                        {   alignment: go.Spot.Left},
                        $(go.Picture, {  width: 20, height: 20,imageStretch: go.GraphObject.Uniform,  alignment: go.Spot.Bottom },
                            new go.Binding("source", "icon")),
                        $(go.Shape,  // the border
                            "RoundedRectangle",
                            {  shadowVisible: false, width:10, height:5, stroke: "transparent", fill: "white",},
                        ),
                        $(
                            go.TextBlock,
                            {shadowVisible: false, textAlign: "left", stroke: 'gray', font: "15px Arial",width: 140,overflow: go.TextBlock.OverflowClip, alignment: go.Spot.Bottom, verticalAlignment: go.Spot.Bottom },
                            new go.Binding("text"),
                        ),
                    ),
                    $(go.Shape,  // the border
                            "RoundedRectangle",
                            {  shadowVisible: false, width:10, height:7, stroke: "transparent", fill: "white",},
                        ),
                    $(
                        go.Panel,
                        "Vertical",  
                        {alignment: go.Spot.Center },
                        $(
                            go.TextBlock,
                            {textAlign: "center", stroke: 'black',text: "verticalAlignment: Center", height: 30},
                            new go.Binding("text", "indDato"),
                            new go.Binding("font", "indDato", (e) => { return e.indexOf('N/A') >=0 ? "15px Arial" : "bold 30px Arial"} ),
                            new go.Binding("stroke", "indDato", (e) => { return e.indexOf('N/A') >=0 ?  'gray' :  'black'} ),
                        ),
                    ),
                    
                )  // end Vertical Panel
            ),new go.Binding("opacity", "isHighlighted", function(h) { return h ? 1 : 0.1; }).ofObject(),
            $(
                go.Panel,
                "Auto",
                
                {  shadowVisible: true, alignment: go.Spot.Center, position: new go.Point(155, -10)},
                $(
                    go.Shape,  // the border
                    "Ellipse",
                    {  shadowVisible: false, height:30, width: 30, stroke: "white"},
                    new go.Binding("fill", "colorAlertaRatio"),
                    
                    
                    // the Shape.stroke color depends on whether Node.isHighlighted is true
                    // new go.Binding("stroke", "isHighlighted", function(h) { return h ? 'blue' : "transparent"; }),
                ),
                $(
                    go.Panel,
                    "Vertical",  
                    {alignment: go.Spot.Center ,height:11, width: 40,},
                    $(
                        go.TextBlock,
                        {textAlign: "center", stroke: 'white',text: "verticalAlignment: Center", height: 11, font: "11px Arial"},
                        new go.Binding("text", "porcentajeRatio", function(n) { 
                            return n.toFixed(0).toLocaleString() + "%"
    
                        }),
                    ),
                ),
                new go.Binding("visible", "colorAlertaRatio", function(n) { 
                    
                    if(n !== null){
                        return true;
                    }else{
                        return false
                    }

                }),
            ),
            {
                toolTip:  // define a tooltip for each node that displays the color as text
                $("ToolTip",
                    $(go.TextBlock, { margin: 4, },
                    new go.Binding("text", "indDato", (e) => { return e.indexOf('N/A') >=0 ?  'No aplica' :  'Días de Stock'} ),
                    ),
                )  // end of Adornment
            }
        ),
       
    );

    // Plantilla para el nodo general (el que más se utilizará)
    // let perimetroTemplate =
    // $(
    //     go.Node,
    //     "Vertical",
    //     {
    //         cursor: 'pointer',
    //         selectionAdorned: false,
    //         resizable: false,
    //         isShadowed: true, 
    //         // shadowBlur: 1,
    //         shadowOffset: new go.Point(0, 1),
    //         shadowColor: "rgba(0, 0, 0, .14)",
    //         // toSpot: go.Spot.parse("0 0.5 2 3"),
    //         // fromSpot: go.Spot.parse("1 0.5 2 3"),
    //         fromSpot: go.Spot.BottomSide,  // coming out from right side
    //         toSpot: go.Spot.TopSide
    //     },
    //     new go.Binding("location", "loc", go.Point.parse),
    //     $(
    //         go.Panel,
    //         "Rectangle",
    //         {width: 120, height: 30, background: "white", margin: 0},
    //         new go.Binding("background", "ind"),
    //         $(
    //             go.TextBlock,
    //             {shadowVisible: false, width: 119, height: 30, textAlign: "center", text: "verticalAligment: Center", verticalAlignment: go.Spot.Center, },
    //             new go.Binding("text")
    //         )
    //     ),
    //     $(
    //         go.Panel,
    //         "Auto",
    //         // { background: lightGrey, width: 120, height: 110, shadowVisible: "true"},
    //         $(go.Shape,  // the border
    //             // { fill: "transparent", shadowVisible: "true"}
    //         ),
    //         $( go.Panel,
    //             "Vertical",  // everything within the border
    //             $(
    //                 go.Panel,
    //                 "Horizontal",  // the row of status indicators
    //             ),  // end Horizontal Panel
    //             $(
    //                 go.Picture,
    //                 { width: 20, height: 20, margin: 0 },
    //                 // new go.Binding("source", "icon", makeImagePath=undefined)
    //                 new go.Binding("source", "icon", undefined)
    //             ),
    //         )  // end Vertical Panel
    //     ),
    //     $(
    //     "TreeExpanderButton",
    //     { alignment: go.Spot.Bottom, alignmentFocus: go.Spot.Top },
    //         { visible: true }
    //     ),
    // //{click: info}// end Auto Panel
    // );

// Añadir diferentes plantillas de diferentes nodos. Después a cada nodo le tendremos que especificar qué
// plantilla tiene que usar.
let templmap = new go.Map();
templmap.add("general", generalTemplate);
// templmap.add("perimetro", perimetroTemplate);
templmap.add("", diagram.nodeTemplate);
return templmap
}

