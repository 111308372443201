import React, { Component, useEffect, useRef } from 'react';  
import { OPCION_AREA_ANALISIS_CENTRO, OPCION_AREA_ANALISIS_PLATAFORMA, OPCION_AREA_ANALISIS_STOCK_GLOBAL } from '../Filtros/FiltrosAnalisis';

type PropsTableauReact ={
    areaAnalisis: string;
    nodo: string | undefined;
}

const TableauReact = (props:PropsTableauReact) => {
    const {tableau} = window;

    const vizContainerCentros = useRef<HTMLDivElement | null>(null)
    const vizContainerPlataformas = useRef<HTMLDivElement | null>(null)
    const vizContainerStockGlobal = useRef<HTMLDivElement | null>(null)

    useEffect( () => {
        if(props.areaAnalisis === OPCION_AREA_ANALISIS_CENTRO){
            initVizCentros();
        }else if (props.areaAnalisis === OPCION_AREA_ANALISIS_PLATAFORMA){
            initVizPlataformas();
        }else{
            initVizStockGlobal();
        }        
    },[props.areaAnalisis]);


    function initVizCentros() {
        let vizUrlCentros = "";
        if(props.nodo){
            vizUrlCentros = 'https://dub01.online.tableau.com/t/verlarreporting/views/StockEuroPool_LightMode_Jonversion_alertas/Stockcentros?Region DESC corregido='+props.nodo+'&:embed=y&:showVizHome=no&:host_url=https%3A%2F%2Fdub01.online.tableau.com%2F&:embed_code_version=3&:tabs=no&:toolbar=yes&:showAppBanner=false&:display_spinner=no&:loadOrderID=0&:device=desktop'
        }else{
            vizUrlCentros = 'https://dub01.online.tableau.com/t/verlarreporting/views/StockEuroPool_LightMode_Jonversion_alertas/Stockcentros?:embed=y&:showVizHome=no&:host_url=https%3A%2F%2Fdub01.online.tableau.com%2F&:embed_code_version=3&:tabs=no&:toolbar=yes&:showAppBanner=false&:display_spinner=no&:loadOrderID=0&:device=desktop'
        } 
        if(vizContainerCentros !== null && vizContainerCentros.current !== null){
            new tableau.Viz(vizContainerCentros.current as HTMLElement, vizUrlCentros)
        }
    }
    
    function initVizPlataformas() {
        let vizUrlPlataformas = "";
        if(props.nodo){
            vizUrlPlataformas = 'https://dub01.online.tableau.com/t/verlarreporting/views/StockEuroPool_LightMode_Jonversion_alertas/StockPlataformas?Nodo_DESC_1='+props.nodo+'&:embed=y&:showVizHome=no&:host_url=https%3A%2F%2Fdub01.online.tableau.com%2F&:embed_code_version=3&:tabs=no&:toolbar=yes&:showAppBanner=false&:display_spinner=no&:loadOrderID=0&:device=desktop'
        }else{
            vizUrlPlataformas = 'https://dub01.online.tableau.com/t/verlarreporting/views/StockEuroPool_LightMode_Jonversion_alertas/StockPlataformas?:embed=y&:showVizHome=no&:host_url=https%3A%2F%2Fdub01.online.tableau.com%2F&:embed_code_version=3&:tabs=no&:toolbar=yes&:showAppBanner=false&:display_spinner=no&:loadOrderID=0&:device=desktop'
        }
        if(vizContainerPlataformas !== null && vizContainerPlataformas.current !== null){
           new tableau.Viz(vizContainerPlataformas.current as HTMLElement, vizUrlPlataformas)
        }
    }

    function initVizStockGlobal() {
        const vizUrlStockGlobal = 'https://dub01.online.tableau.com/t/verlarreporting/views/StockEuroPool_LightMode_Jonversion_alertas/StockGlobal?:embed=y&:showVizHome=no&:host_url=https%3A%2F%2Fdub01.online.tableau.com%2F&:embed_code_version=3&:tabs=no&:toolbar=yes&:showAppBanner=false&:display_spinner=no&:loadOrderID=0&:device=desktop'
       if(vizContainerStockGlobal !== null && vizContainerStockGlobal.current !== null){
           new tableau.Viz(vizContainerStockGlobal.current as HTMLElement, vizUrlStockGlobal)
        }
    }
    
    return (
        <div>
        {props.areaAnalisis === OPCION_AREA_ANALISIS_CENTRO &&
            <div ref={vizContainerCentros} className="vizContainer"></div>
        }
        {props.areaAnalisis === OPCION_AREA_ANALISIS_PLATAFORMA &&
            <div ref={vizContainerPlataformas} className="vizContainer"></div>
        }
        {props.areaAnalisis === OPCION_AREA_ANALISIS_STOCK_GLOBAL &&
            <div ref={vizContainerStockGlobal} className="vizContainer"></div>
        }        
        </div>

        )
    }
    


 export default TableauReact;