import React, { useEffect, useState } from "react";
import TableauReact from "../../components/Analisis/Tableau";
import Aside from "../../components/core/Layout/Aside";
import FiltrosAnalisis, { OPCION_AREA_ANALISIS_CENTRO, OPCION_AREA_ANALISIS_PLATAFORMA, OPCION_AREA_ANALISIS_STOCK_GLOBAL } from "../../components/Filtros/FiltrosAnalisis";
import { useLocation, useParams } from 'react-router-dom'

type StateAnalisis ={
    id: string;
    nodo: string;
}

const Analisis = () => {

    const location = useLocation()

    let valorIncial = OPCION_AREA_ANALISIS_CENTRO
    let valorNodoInicial = undefined
    if(location.state){
        const state:StateAnalisis = location.state as StateAnalisis
        
        if(state.id !== undefined){
            valorIncial = state.id
        }
        
        if(state.nodo !== undefined){
            valorNodoInicial = state.nodo
        }
    }

    const [areaAnalisis, setAreaAnalisis] = useState<string>(valorIncial);
    const [nodo, setNodo] = useState<string | undefined>(valorNodoInicial);

    useEffect(() => {
        setNodo(undefined)
    },[areaAnalisis])

    return (

        <div className="container-pagina container-analisis">
            <Aside>
                <FiltrosAnalisis areaAnalisis={areaAnalisis} callbackAreaAnalisisCambiado={setAreaAnalisis}></FiltrosAnalisis>
                
            </Aside>
            <main>
            {/* <!--Tenemos un dashboard para plataformas y otro para centros--> */}
                <TableauReact areaAnalisis={areaAnalisis} nodo={nodo}></TableauReact>
                {/* <!--tableau de centros. Iremos enseñando un tableau u otro según pida el usuario--> */}
                 {/* <div className="content" id="centros" >
                    <div className='tableauPlaceholder'>
                        <object className='tableauViz' width='80%' height='1185' >
                            <param name='host_url' value='https%3A%2F%2Fdub01.online.tableau.com%2F'/>
                            <param name='embed_code_version' value='3'/>
                            <param name='site_root' value='&#47;t&#47;verlarreporting'/>
                            <param name='name' value='StockEuroPool&#47;Stockcentros'/>
                            <param name='tabs' value='no'/>
                            <param name='toolbar' value='yes'/>
                            <param name='showAppBanner' value='false'/>
                        </object>
                    </div>
                </div> */}

                {/* <!--tableau de plataformas. Iremos enseñando un tableau u otro según pida el usuario--> */}
                {/* <div className="content" id="plataformas" style={{display:'none'}}> 
                    <script type='text/javascript' src='https://dub01.online.tableau.com/javascripts/api/viz_v1.js'></script>
                    <div className='tableauPlaceholder'>
                        <object className='tableauViz' width='80%' height='1185' style={{display:'none'}}>
                            <param name='host_url' value='https%3A%2F%2Fdub01.online.tableau.com%2F' />
                            <param name='embed_code_version' value='3' />
                            <param name='site_root' value='&#47;t&#47;verlarreporting' />
                            <param name='name' value='StockEuroPool&#47;StockPlataformas' />
                            <param name='tabs' value='no'/>
                            <param name='toolbar' value='yes'/>
                            <param name='showAppBanner' value='false'/>
                        </object>
                    </div>
                </div> */}
            </main>   
        </div>
    )
}

export default Analisis