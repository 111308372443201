import { AxiosError, AxiosResponse } from 'axios';
import React, { useEffect } from 'react';
import { URL_FLUJO } from '../constants/constants';
import LoginService from "../services/LoginSevice";

const authContext = React.createContext({} as useAuthType);

interface useAuthType {
    authed: boolean | undefined,
    login: (user:string, password:string) =>  Promise<unknown> ,
    loginSiSesionAnterior: () => void,
    logout: () => void,
}

export function setToken(token:string){
    window.sessionStorage.setItem('token', token)
}

export function getToken(){
    return window.sessionStorage.getItem('token')
}
function borrarToken(){
  	window.sessionStorage.clear()
}


function useAuth() {
  	const [authed, setAuthed] = React.useState<boolean | undefined>(undefined);

    /* INICIO: WSO2IS */
    function loginSiSesionAnterior () {
        // See if there is a valid session.
        // let isLogged = window.sessionStorage.getItem('isLogged');
        let token = getToken();
        if(token !== null){
			LoginService.getSesion()
				.then((response:any )=> {
					setAuthed(true);
				})
				.catch((error => {
					borrarToken()
					setAuthed(false)
				}));
        }else{
            setAuthed(false)
        }
            
        return;
        
    }

	function login(user:string, password:string) {
        //SE LLAMA AL HACER LOGIN
        return new Promise((resolve, reject) => {
            LoginService.postLogin(user, password).then( (res: AxiosResponse<any,any>) => {
                setToken(res.data.token);
                setAuthed(true);
                resolve('');
            })
            .catch((reason: AxiosError) => {
                reject(reason)
            })
        })
    }

    function logout() {
        LoginService.getLogout().then( (res: AxiosResponse<any,any>) => {
			borrarToken();
          	setAuthed(false);
      	})
		.catch((reason: AxiosError) => {
		})
    }
  


	return {
		authed,
		loginSiSesionAnterior,
		login,
		logout,
		
	};
}

interface StoreProviderProps {
    children: React.ReactElement;
}




export function AuthProvider({children}: StoreProviderProps):  React.ReactElement {
  const auth = useAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export default function AuthConsumer() {
  return React.useContext(authContext);
}