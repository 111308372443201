import React, { useEffect, useState, useMemo } from "react";
import Aside from "../../components/core/Layout/Aside";
import FiltrosFlujos, { OPCION_FLUJO_FRESCO, OPCION_FLUJO_FRESCO_CARNE, OPCION_FLUJO_FRESCO_FRUTA, OPCION_FLUJO_FRESCO_PESCADO, OPCION_FLUJO_FRESCO_REFRIGERADO, OPCION_FLUJO_SECO, OPCION_TIPO_REFERENCIA_ENVASES } from "../../components/Filtros/FiltrosFlujos";
import DiagramaGoJS from "../../components/Flujos/DiagramaGoJS";
import LeyendaFlujos from "../../components/Flujos/LeyendaFlujos";
import ResumentStockAcumulado from "../../components/Flujos/ResumentStockAcumulado";
import mensajeSinFiltros from "../../img/msg-no-filters-selected.svg";
import logoVpo from "../../img/vpo_ingenieria_logo.png";

import { getFecha } from "../../utils/Fechas";

export type flujoValues = {
    diaDesde?: string,
    diaHasta?: string,
    flujo: string[],
    proceso: string[],
    tipoReferencia?: string
}

export function tieneSeleccionadoTodoProcesosFrescos(flujoValues: flujoValues):boolean{
    return (flujoValues.proceso.indexOf(OPCION_FLUJO_FRESCO_FRUTA) > -1 && flujoValues.proceso.indexOf(OPCION_FLUJO_FRESCO_REFRIGERADO) > -1 && flujoValues.proceso.indexOf(OPCION_FLUJO_FRESCO_CARNE) > -1 && flujoValues.proceso.indexOf(OPCION_FLUJO_FRESCO_PESCADO) > -1)
}
//function tieneSeleccionadoTodoProcesosSecos():boolean{
//    return (proceso.indexOf(OPCION_FLUJO_SECO_ALIMENTACION) > -1 && proceso.indexOf(OPCION_FLUJO_SECO_BAJAROTACION) > -1)
//}
export function tieneSeleccionadoSecos(flujoValues: flujoValues):boolean{
    return (flujoValues.flujo && flujoValues.flujo.indexOf(OPCION_FLUJO_SECO) > -1)
}
export function tieneSeleccionadoTodoFlujos(flujoValues: flujoValues):boolean{
    return (flujoValues.flujo.indexOf(OPCION_FLUJO_FRESCO) > -1 && flujoValues.flujo.indexOf(OPCION_FLUJO_SECO) > -1)
}

const Flujos = () => {

    const fechaInicioPorDefecto = useMemo(() => {
        const fechaHoy = new Date();
        const dosSemanaAtrasLunes = new Date(fechaHoy.setDate(fechaHoy.getDate() - fechaHoy.getDay() + 1 - 14));
        return getFecha(dosSemanaAtrasLunes)
    }, [])

    const fechaFinPorDefecto = useMemo(() => {
        const fechaHoy = new Date();
        const dosSemanaAtrasDomingo = new Date(fechaHoy.setDate(fechaHoy.getDate() - fechaHoy.getDay() + 1 - 8));
        return getFecha(dosSemanaAtrasDomingo)
    }, [])

    const [flujoValues, setflujoValues] = useState<flujoValues>(
        { 
            diaDesde: fechaInicioPorDefecto, 
            diaHasta: fechaFinPorDefecto,
            flujo: [OPCION_FLUJO_FRESCO, OPCION_FLUJO_SECO],
            proceso: [OPCION_FLUJO_FRESCO_FRUTA, OPCION_FLUJO_FRESCO_REFRIGERADO, OPCION_FLUJO_FRESCO_CARNE, OPCION_FLUJO_FRESCO_PESCADO],
            tipoReferencia: OPCION_TIPO_REFERENCIA_ENVASES 
        }
    );

    const [urlFiltros, setUrlFiltros] = useState<string | undefined>(undefined)

    useEffect( () => {
        setUrlFiltros(getUrl());
    }, [flujoValues])

    function getUrl(){
        // Detectar elementos filtrados
        // let dia_inicio = inputDiaInicio.value;
        // let dia_fin = inputDiaFin.value;
        // dia_fin = getDates(new Date(diaDesde), new Date(diaHasta));
        let url = ''
       
        // console.log(proceso);
        // console.log("OPCION_FLUJO_FRESCO "+flujo.indexOf(OPCION_FLUJO_FRESCO))
        // console.log("OPCION_FLUJO_SECO "+flujo.indexOf(OPCION_FLUJO_SECO))
        // console.log("flujo "+flujo.length)
        if(flujoValues.proceso?.length === 0 && !tieneSeleccionadoSecos(flujoValues)){
            return url;
        }

        // El usuario ha podido dejar vacío el campo de fecha para ver el histórico desde que hay dato
        // Si ha elegido fechas las metemos en la URL diciendo que filtre fechas mayores a fecha inicio y fechas menores a fecha fin
        if (flujoValues.diaDesde !== '' && flujoValues.diaHasta !== '') {
            url += `fecha__gte=${flujoValues.diaDesde}&fecha__lte=${flujoValues.diaHasta}`;
        }
        
        if(!(tieneSeleccionadoTodoFlujos(flujoValues))){
            if (flujoValues.flujo?.length > 1){
                url += `&flujo__in=${flujoValues.flujo.join(',')}`; //en la base de datos está en minúscula
            }else if(flujoValues.flujo.length > 0){
                url += `&flujo=${flujoValues.flujo[0]}`; //en la base de datos está en minúscula
            }
        }
      

        if(!(
            (tieneSeleccionadoTodoFlujos(flujoValues) && tieneSeleccionadoTodoProcesosFrescos(flujoValues) 
            //&& tieneSeleccionadoTodoProcesosSecos()
            && tieneSeleccionadoSecos(flujoValues)
            ) ||
            (flujoValues.flujo?.length === 1 && flujoValues.flujo.indexOf(OPCION_FLUJO_FRESCO) > -1 && tieneSeleccionadoTodoProcesosFrescos(flujoValues)) ||
            (flujoValues.flujo?.length === 1 && flujoValues.flujo?.indexOf(OPCION_FLUJO_SECO) > -1 
            //&& tieneSeleccionadoTodoProcesosSecos()
            )
            ) ){
                if (flujoValues.proceso.length > 1 && !tieneSeleccionadoSecos(flujoValues)){
                    url += `&proceso__in=${flujoValues.proceso.join(',')}`; //en la base de datos está en minúscula
                }else if(flujoValues.proceso.length > 0 && tieneSeleccionadoSecos(flujoValues)){
                    url += `&proceso__in=${flujoValues.proceso.join(',')},0`; //en la base de datos está en minúscula
                }else if(flujoValues.proceso.length > 0){
                    url += `&proceso=${flujoValues.proceso[0]}`; //en la base de datos está en minúscula
                }
        }
       
        if (flujoValues.tipoReferencia !== 'todo'){
            url += `&tipo_referencia=${flujoValues.tipoReferencia}`;
        }

        if(url !== ''){
            url = '?' + url;
        }

        // console.log("URL:___________________ "+url)
        return url;
    }

    return (

        <div className="container-pagina container-flujos">
            <Aside asideFlujos={true}>
                <>
                    <ResumentStockAcumulado filtros={flujoValues}></ResumentStockAcumulado>
                    <FiltrosFlujos filtros={flujoValues} callbackFiltrosCambiado={(e) =>{ setflujoValues(e)}}></FiltrosFlujos>
                    <LeyendaFlujos></LeyendaFlujos>
                    {/* <ResumentStockAcumulado></ResumentStockAcumulado> */}
                </>
            </Aside>
            <main>
                {
                    urlFiltros !== undefined &&
                        urlFiltros !== "" ?
                            <DiagramaGoJS key={urlFiltros} urlFiltros={urlFiltros} filtrosValues={flujoValues} />
                        :
                            <div className="mensaje-sin-filtros">
                                <img src={mensajeSinFiltros} alt="Ningun filtro seleccionado" />
                            </div>

                }
            </main>
            <div className="logo_vpo_flujos">
                <a href="https://www.vpoingenieria.com" target={"_blank"}><img src={logoVpo}/></a>
            </div>
        </div>
    )
}

export default Flujos