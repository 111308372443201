import axios, { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { URL_ANALISIS } from "../../constants/constants";
import { flujoValues } from "../../pages/Flujos";
import {getStock, getStockAcumulado} from "../../services/FlujosService";
import { OPCION_AREA_ANALISIS_STOCK_GLOBAL } from "../../components/Filtros/FiltrosAnalisis";

type PropsFiltrosFlujos = {
    filtros: flujoValues;
    callbackFiltrosCambiado: (filtros: flujoValues) => void
}

const ResumentStockAcumulado = (props: any) => {

    const [stockTotal, setStockTotal] = useState<number | undefined>(undefined)
    const [stockPlataformas, setStockPlataformas] = useState<number | undefined>(undefined)
    const [stockTiendas, setStockTiendas] = useState<number | undefined>(undefined)

    useEffect( () => {
        if(props.filtros.diaHasta){
            axios.all([
                getStock("?fecha="+props.filtros.diaHasta)
            ]).then(axios.spread((stockResp) => {
                if(stockResp?.status === 200 && stockResp.data.length>0){

                    let datoPlataformas = stockResp.data.find( (e : any) => e.nodo_tipo_id_ === 3).stock_envases;
                    let datoTiendas = stockResp.data.find( (e : any) => e.nodo_tipo_id_ === 4).stock_envases;

                    setStockPlataformas(datoPlataformas)
                    setStockTiendas(datoTiendas)
                    setStockTotal(datoPlataformas+datoTiendas)
                    
                }
                
            }))
        }
    },[props.filtros.diaHasta])

    return(
        <>
        {(stockTotal && stockPlataformas && stockTiendas &&
            
            <div className="aside_datos_extra">
                <Link to={URL_ANALISIS} state={{id: OPCION_AREA_ANALISIS_STOCK_GLOBAL}}>
                    <div className="stock_total">
                        <h2>Stock Total</h2>
                        <p>{stockTotal.toLocaleString('de-DE')}</p>
                    </div>
                </Link>
                <Link to={URL_ANALISIS} state={{id: OPCION_AREA_ANALISIS_STOCK_GLOBAL}} >
                    <div className="platafomas_tiendas">
                        <div>
                            <h2>Plataformas</h2>
                            <p>{stockPlataformas.toLocaleString('de-DE')}</p>
                        </div>
                        <div>
                            <h2>Tiendas</h2>
                            <p>{stockTiendas.toLocaleString('de-DE')}</p>
                        </div>
                    </div>
                </Link>
            </div>
        )}
        </>
    )
}
export default ResumentStockAcumulado

// async function getTable(){
//     // Crear URL
//     let urlTabla = getUrl(urlIndicadoresTabla + '?')

//     // Request a django
//     let answer = await fetch(urlTabla)
//     let indTabla = await answer.json()

//     let in_plat = indTabla[0] //Entrada a plataformas
//     let out_plat = indTabla[1] //Salida desde plataformas
//     let in_centro = indTabla[2] //Entrada a centros
//     let out_centro = indTabla[3] //Salida desde centros
//     let in_ret = indTabla[3] //Entrada a centros de retorno
//     if (typeof out_centro == 'undefined'){ //hay algunos días de la semana que no existen retornos, por ello no existirá ``indTabla[3]``
//         out_centro = {'n_envases': 0, 'n_maxicrate': 0, 'n_mercancias': 0}
//         in_ret = {'n_envases': 0, 'n_maxicrate': 0, 'n_mercancias': 0}
//     }
//     let out_ret = 0 //Salida desde centro de retornos (de momento no tenemos dato)

//     //Escribir la tabla con los datos obtenidos de django
//     divTable.innerHTML=
//     `
//     <table class="table table-hover">
//           <thead>
//             <tr>
//               <th scope="col"> </th>
//               <th scope="col">Envases</th>
//               <th scope="col">Maxicrate</th>
//               <th scope="col">Mercancías</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <th scope="row">Plataformas</th>
//               <td>${Math.ceil(in_plat.n_envases - out_plat.n_envases)}</td>
//               <td>${Math.ceil(in_plat.n_maxicrate - out_plat.n_maxicrate)}</td>
//               <td>${Math.ceil(in_plat.n_mercancias - out_plat.n_mercancias)}</td>
//             </tr>
//             <tr>
//               <th scope="row">Centros</th>
//               <td>${Math.ceil(in_centro.n_envases - out_centro.n_envases)}</td>
//               <td>${Math.ceil(in_centro.n_maxicrate - out_centro.n_maxicrate)}</td>
//               <td>${Math.ceil(in_centro.n_mercancias - out_centro.n_mercancias)}</td>
//             </tr>
//             <tr>
//               <th scope="row">Centros de retorno</th>
//               <td>${Math.ceil(in_ret.n_envases - out_ret)}</td>
//               <td>0</td>
//               <td>0</td>
//             </tr>
//           </tbody>
//         </table>
//     `
// }