const LeyendaFlujos = () => {


    return (
        <div className="aside-leyenda">
                <h3>Leyenda Flujos</h3>
                <div>
                    <span className="leyenda leyenda-frescos">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                    <p>Frescos</p>
                </div>
                <div>
                    <span className="leyenda leyenda-seco">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                    <p>Seco</p>
                </div>
            </div>
    )
}
export default LeyendaFlujos


