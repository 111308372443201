import React, { ChangeEvent, useEffect, useState } from "react";


export const OPCION_AREA_ANALISIS_CENTRO = '1';
export const OPCION_AREA_ANALISIS_PLATAFORMA = '2';
export const OPCION_AREA_ANALISIS_STOCK_GLOBAL = '3';


type PropsFiltrosAnalisis = {
    areaAnalisis: string;
    callbackAreaAnalisisCambiado: (areaAnalisis: string) => void
}

const FiltrosAnalisis = (props: PropsFiltrosAnalisis) => {

    const areaAnalisisCambiado = (e: ChangeEvent<HTMLSelectElement>) => {
        props.callbackAreaAnalisisCambiado(e.target.value)
    }
    return(


        <div className="aside-analisis__filtros">
            <div className="aside-analisis__filtros__area-analisis">

            <label>Área de análisis</label>
            <select onChange={areaAnalisisCambiado} value={props.areaAnalisis}>
                <option value={OPCION_AREA_ANALISIS_CENTRO}>Centros</option>
                <option value={OPCION_AREA_ANALISIS_PLATAFORMA}>Plataformas</option>
                <option value={OPCION_AREA_ANALISIS_STOCK_GLOBAL}>Stock Global</option>
            </select>
            </div>
        </div>

)
}

export default FiltrosAnalisis