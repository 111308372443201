import axios from "axios";
import { http } from "./utils/http";

//*************** Flujos ***************//

 const getStockAcumulado = (filtros:string) => {
    return http.get( "/ind_tabla/"+filtros );
};

const getLinksGeneral = (filtros:string) => {
    return http.get( "/links/"+filtros );
};
const getNodosGeneral = (filtros:string) => {
    return http.get( "/nodos/"+filtros );
};
const getMasterNodos = () => {
    return http.get( "/nodos-maestro/" );
};
const getIndicadoresEntradas = (filtros:string) => {
    return http.get( "/ind_entradas/"+filtros );
};
const getIndicadoresSalida = (filtros:string) => {
    return http.get( "/ind_salidas/"+filtros );
};
const getIndicadoresAutoconsumo = (filtros:string) => {
    return http.get( "/autoconsumo/"+filtros );
};

const getIndicadoresTotal = (filtros:string) => {
    return http.get( "/total/"+filtros );

}

const getAlertas = (objeto: Object) => {
    return http.post( "/alertas/", objeto );
}

const getRatioTiendas = (filtros:string) => {
    return http.get( "/ratio_tienda/"+filtros );
}

const getStock = (filtros:string) => {
    return http.get( "/stock/"+filtros );
}



//*************** Forma de llamarlo ***************//

// getDevices().then((value) => {
//     console.log(value)
// })

export {getStockAcumulado, getLinksGeneral, getNodosGeneral, getIndicadoresAutoconsumo, getIndicadoresEntradas, getIndicadoresSalida, getIndicadoresTotal, getMasterNodos, getAlertas, getRatioTiendas, getStock};




// const urlIndicadoresSalida = "{% url 'core:indicadores-salida' %}";
// const urlIndicadoresEntrada = "{% url 'core:indicadores-entrada' %}";
// const urlIndicadoresAutoconsumo = "{% url 'core:indicadores-autoconsumo' %}";
// const urlIndicadoresTabla = "{% url 'core:indicadores-tabla' %}";
// const urlFlujoTotal = "{% url 'core:flujo-total' %}";
// const urlAnalisis = "{% url 'core:powerBI' %}";

// path('analisis/', AnalisisView.as_view(), name='powerBI'),
// path('', FlujosView.as_view(), name='general'),
// path('links/', LinkListAPIView.as_view(), name='links-general'),
// path('nodos/', NodoListAPIView.as_view(), name='nodos-general'),
// path('ind_salidas/', SalidasListAPIView.as_view(), name='indicadores-salida'),
// path('ind_entradas/', EntradasListAPIView.as_view(), name='indicadores-entrada'),
// path('autoconsumo/', AutoconsumosListAPIView.as_view(), name='indicadores-autoconsumo'),
// # TODO:
// path('ind_tabla/', LinkListAPIView.as_view(), name='indicadores-tabla'),
// path('total/', FlujoTotalListAPIView.as_view(), name='flujo-total'),