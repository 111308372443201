import React, { useEffect, useState } from "react";
import { NodoFlujosFields } from "./DiagramaGoJS";
import close from '../../img/close.svg'
import linkedImg from '../../img/linked.png'
import { URL_ANALISIS } from "../../constants/constants";
import { Link } from "react-router-dom";
import axios from "axios";
import { getRatioTiendas, getStock } from "../../services/FlujosService";
import { flujoValues } from "../../pages/Flujos";
import { OPCION_AREA_ANALISIS_CENTRO, OPCION_AREA_ANALISIS_PLATAFORMA } from "../Filtros/FiltrosAnalisis";

type PropsDetalleNodoSeleccionado = {
    nodo: NodoFlujosFields;
    filtrosValues: flujoValues;
    onClose: () => void;
}

const DetalleNodoSeleccionado = (props: PropsDetalleNodoSeleccionado)  => {

    let opcionAnalisis = OPCION_AREA_ANALISIS_CENTRO;
    let nodoTexto = undefined
    if(props.nodo.nodo_tipo_id === 3){
        opcionAnalisis = OPCION_AREA_ANALISIS_PLATAFORMA
        nodoTexto = props.nodo.plataforma_gbi_desc

    }else if(props.nodo.nodo_tipo_id === 4){
        nodoTexto = props.nodo.text;
    }
    
    let nTiendasAlertadas = undefined
    let colorAlertaTienda = ""
    if(props.nodo.ratioTienda && props.nodo.ratioTienda.alerta !== undefined && props.nodo.ratioTienda.total !== undefined && props.nodo.porcentajeRatio !== undefined){
        nTiendasAlertadas = props.nodo.ratioTienda?.alerta + "/" + props.nodo.ratioTienda?.total + " (" + props.nodo.porcentajeRatio.toFixed(0).toLocaleString() + "%)"
        if(props.nodo.colorAlertaRatio){
            colorAlertaTienda = props.nodo.colorAlertaRatio;
        }
    }

    //console.log(props.nodo.alertColor)
    let colorAlertaStock = ""
    if(props.nodo.alertColor){
        console.log(1)
        if(props.nodo.alertColor === "rojo"){
            console.log(2)
            colorAlertaStock = "#EF0000"
        }else if(props.nodo.alertColor === "amarillo"){
            console.log(3)
            colorAlertaStock = "#C9C56D"
        }
    }
    //console.log(colorAlertaStock)
    
    return (
        <div>
            <div className="detalle-nodo-clickado__titulo">
                <div>
                    {props.nodo.icon &&
                        <img src={props.nodo.icon}></img>
                    }
                    <label>{props.nodo.text}</label>
                </div>
                <img className="detalle-nodo-clickado__close" src={close} alt={'Cerrar'} onClick={props.onClose}></img>
            </div>
            {/* <div className="separador"></div> */}
            <div className="detalle-nodo-clickado__content">
                <div className="detalle-nodo-clickado__info">
                    {props.nodo.indDatoDiasStock !== null &&
                        <div>
                            <label htmlFor="">Días de stock</label>
                            <span style={{color: colorAlertaStock}}>{props.nodo.indDatoDiasStock.toLocaleString('de-DE')}</span>
                        </div>
                    }
                    {props.nodo.indDatoPorc !== null &&
                    <div>
                        <label htmlFor="">% Salidas/Entradas</label>
                        <span>{props.nodo.indDatoPorc.toFixed(2).toLocaleString()}%</span>
                    </div>
                    }
                    {props.nodo.indDatoIn !== null &&
                    <div>
                        <label htmlFor="">Entradas</label>
                        <span>{props.nodo.indDatoIn.toLocaleString('de-DE')}</span>
                    </div>
                    }
                    {props.nodo.indDatoOut !== null &&
                    <div>
                        <label htmlFor="">Salidas</label>
                        <span>{props.nodo.indDatoOut.toLocaleString('de-DE')}</span>
                    </div>
                    }
                    {props.nodo.indDatoAuto !== null &&
                    <div>
                        <label htmlFor="">Autoconsumos</label>
                        <span>{props.nodo.indDatoAuto.toLocaleString('de-DE')}</span>
                    </div>
                    }
                    {props.nodo.indDatoDif !== null &&
                    <div>
                        <label htmlFor="">Diferencia Salidas/Entradas</label>
                        <span>{props.nodo.indDatoDif.toLocaleString('de-DE')}</span>
                    </div>
                    }
                    {nTiendasAlertadas !== undefined && 
                    <div>
                        <label htmlFor="">Nº Tiendas alertadas</label>
                        <span style={{color: colorAlertaTienda}}>{nTiendasAlertadas}</span>
                    </div>
                    }
                    {props.nodo.stock !== undefined &&
                    <div>
                        <label htmlFor="">Stock</label>
                        <span>{props.nodo.stock.toLocaleString('de-DE')}</span>
                    </div>
                    }
                    {(([1,2,3,4,5].includes(props.nodo.nodo_id) && props.nodo.nodo_tipo_id == 3) || props.nodo.nodo_tipo_id == 4) &&
                    <div className="linkDetalle">
                        <Link to={URL_ANALISIS} state={{ id: opcionAnalisis, nodo:  nodoTexto}}>
                            <img src={linkedImg}/>
                            <span>Ir a detalle</span>
                        </Link>
                    </div>
                    }
                </div>
                
                {((props.nodo.rojo !== undefined && props.nodo.rojo !== null) || (props.nodo.colorAlertaRatio && props.nodo.colorAlertaRatio !== null)) && 
                <div className="detalle-nodo-clickado__alertas">
                    <h4>Alertas Nodos</h4>
                    {props.nodo.rojo !== undefined && props.nodo.rojo !== null && 
                    <div className="alertas-stock">
                        <p><label></label>Más de {props.nodo.rojo?.toLocaleString('de-DE')} días Stock</p>
                        <p><label></label>Entre {props.nodo.amarillo?.toLocaleString('de-DE')} y {props.nodo.rojo.toLocaleString('de-DE')} días de Stock</p>
                    </div>
                    }
                    {props.nodo.colorAlertaRatio && props.nodo.colorAlertaRatio !== null && 
                    <div className="alertas-tiendas">
                        <p><label></label>+{props.nodo.ratioTienda?.rojo}% Tiendas alertadas</p>
                        <p><label></label>Entre {props.nodo.ratioTienda?.amarillo}% y {props.nodo.ratioTienda?.rojo}% Tiendas alertadas</p>
                    </div>
                    }
                </div>
                }
             
            </div>
        </div>
    )
}

export default DetalleNodoSeleccionado