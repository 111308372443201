
//******** INICIO NAVEGACION ********//

export const URL_FLUJO = '/flujo';
export const URL_ANALISIS = '/analisis';
export const URL_LOGIN = '/';

export const URLS_NAVEGACION = [ {url: URL_FLUJO, texto: 'Flujo'},
                                 {url: URL_ANALISIS, texto: 'Análisis'}
                               ];

//******** FIN NAVEGACION ********//
